<template>
  <section>
    <div class="content-header">
      <h2>Podcasts </h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li class="pb-2"><a href="https://podcasts.apple.com/hu/podcast/im-a-post-covid-graduate-now-what/id1558760059?i=1000524621372" target="_blank">“I’m a post-COVID graduate. Now what?”</a></li>
        <li><a href="https://justworkit.ca/podcast/lovers-fighters-ep-6-decent-work-in-your-workplace/" target="_blank">“Decent Work in Your Workplace”</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
